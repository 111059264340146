import * as React from "react";
import Contact from "./Contact";

export default function About(props) {
    React.useEffect(() => {
    }, [])

    return (
        <>
            <section>
                <div className="backgroundServices"></div>
                <div className="font textAbout">Our Services</div>
            </section>
            <section>
                <div className='container-fluid AppWidth'>
                    <div className='row rowServices'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-5'>
                            <div className='titleServices'>
                                Workflow automation
                            </div>
                            <p className='textServices'>
                                Streamline your accounting and finance workflow: Our custom software automates repetitive office tasks,
                                allowing you to focus on what's important - your clients.
                                <br />
                                <br />
                                Konpu Consulting handles data entry, invoices, accounting, bookkeeping,
                                accounts receivable, accounts payable, among others.
                                Contact Konpu Consulting today and increase your efficiency.
                            </p>
                        </div>
                        <div className='col-sm-5'>
                            <div className='titleListServices'>
                                We handle all financial and accounting data including:
                            </div>
                            <ul className='listServices'>
                                <li>Invoices</li>
                                <li>Accounts Receivable</li>
                                <li>Accounts Payable</li>
                                <li>Invoice Deductions</li>
                            </ul>
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                    <div className='row rowServices backgroundColor2'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-5'>
                            <div className='titleServices'>
                                Data Gathering
                            </div>
                            <p className='textServices'>
                                Stop dealing with retail invoice services
                                that require hours of work to gather
                                your company's data. This type of work
                                is better done automatically to free your
                                team's time, and avoid human error.
                                <br />
                                <br />
                                Konpu Consulting will not be satisfied until you get
                                the full potential of automation software for your business.
                            </p>
                        </div>
                        <div className='col-sm-5'>
                            {/* <div className='titleListServices'>
                                We handle all civil litigation matters including:
                            </div>
                            <ul className='listServices'>
                                <li>Commercial Law</li>
                                <li>Landlord - Tenant</li>
                                <li>Defective Products and Services</li>
                                <li>Property Claims</li>
                            </ul> */}
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                    <div className='row rowServices'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-5'>
                            <div className='titleServices'>
                                Secure, Reliable and Personalized
                            </div>
                            <p className='textServices'>
                                There are no one-size-fits-all solutions.
                                Your software will be tailored made to meet the
                                needs of your business.
                                <br />
                                <br />
                                Your data's security is vital. Our software is designed
                                to keep your data safe and secure at all times.
                            </p>
                        </div>
                        <div className='col-sm-5'>
                            <div className='titleListServices'>
                                Your processes will become:
                            </div>
                            <ul className='listServices'>
                                <li>Customized</li>
                                <li>Reliable</li>
                                <li>Secure</li>
                                <li>Efficient</li>
                            </ul>
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                </div>
            </section>
            <section className='sectionHeight backgroundColor2'>
                <Contact />
            </section>
        </>
    )
}