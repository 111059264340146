import * as React from "react";

export default function About(props) {
    React.useEffect(() => {
    }, [])

    const gotoContact = () => {
        props.gotoContact();
    }

    return (
        <>
            <section className='sectionRelative'>
                <div className="backgroundAbout"></div>
                <div className="font textAbout">About</div>
                <div className="font subtextAbout">
                    Simplify your workflow today, call us and set up an initial
                    consultation immediately.<br />
                    <br />
                    ~ 425-598-9302 ~
                </div>
            </section>
            <section>
                <div className='container-fluid AppWidth'>
                    <div className='row'>
                        <div className='col-sm-12 titleAbout'>
                            Our Practice
                        </div>
                    </div>
                    <div className='row rowAbout'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-10'>
                            <p className='textAbout2'>
                                Konpu Consulting LLC is a Texas based software consulting company with its principal office located in Houston, Texas.
                                Our mission is to use our clients' current tech resources to simplify their workflow, allowing them to focus their time and effort on their own mission.
                                We can provide them with state of the art custom software they need.
                                Konpu Consulting will strive to not only achieve efficiency, but to do it in the simplest way possible.
                            </p>
                            <div className='titleAbout2'>
                                ¡SE HABLA ESPAÑOL!
                            </div>
                            <p className='textAbout2'>
                                Engineer David Kutugata is a Software Developer with years of industry experience in both startups and big tech, including a FAAMG company.
                                His work has taken him from Mexico, to Prague, to Seattle, and to Paris to solve multiple issues in diverse industries.
                                He is bilingual in English and Spanish and caters to Accounting and Finance departments to raise their productivity.
                                He received his Bachelor's degree in Computer Science, with a major in Digital Systems and a minor in Robotics.
                                <br />
                                <br />
                                No matter your workflow, Konpu Consulting is here to maximize your efficiency with your current tech resources.
                            </p>
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                </div>
            </section>
            <section className='sectionRelative'>
                <div className="backgroundAbout2"></div>
                <div className='font4 textPositionAbout'>
                    <div className="textAbout3">Let's Work Together</div>
                </div>
                <div className='font4 buttonPositionAbout'>
                    <button className='btn button4' onClick={gotoContact}>GET IN TOUCH</button>
                </div>
            </section>
        </>
    )
}