import { createRoot } from 'react-dom/client';
import { ToastContainer, toast } from 'react-toastify';
import * as React from "react";
import About from "./About";
import Home from "./Home";
import Services from './Services';
import ContactHub from './ContactHub';

export function App() {

    const [showHome, setShowHome] = React.useState(true);
    const [showAbout, setShowAbout] = React.useState(false);
    const [showServices, setShowServices] = React.useState(false);
    const [showContact, setShowContact] = React.useState(false);

    React.useEffect(()=>{
        setTimeout(() => {
            toast.info('Click Here To Contact Us!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }, 5000);
    },[]);

    const startHome = () => {
        setupPage();
        setShowHome(true);
        setShowAbout(false);
        setShowServices(false);
        setShowContact(false);
    };

    const startAbout = () => {
        setupPage();
        setShowHome(false);
        setShowAbout(true);
        setShowServices(false);
        setShowContact(false);
    };

    const startServices = () => {
        setupPage();
        setShowHome(false);
        setShowAbout(false);
        setShowServices(true);
        setShowContact(false);
    };

    const startContact = () => {
        setupPage();
        setShowHome(false);
        setShowAbout(false);
        setShowServices(false);
        setShowContact(true);
    };

    const setupPage = () => {
        window.scrollTo(0, 0);
        var all = document.getElementById("menuCheck");
        (all as any).checked = false;
    }

    return (
        <div>
            <header className="Menu">
                <input type="checkbox" id="menuCheck" />
                <label htmlFor="check" className="checkbtn">
                    <i className="fa fa-bars"></i>
                </label>
                <div className="float-left">
                    <img src="images/logo2.png" alt="logo" className="logo" onClick={startHome} />
                </div>
                <div className="float-right menuButtons">
                    <button className="menu-button" onClick={startHome}>Home</button>
                    <button className="menu-button" onClick={startAbout}>About</button>
                    <button className="menu-button" onClick={startServices}>Services</button>
                    <button className="menu-button" onClick={startContact}>Contact</button>
                </div>
            </header>
            <div>
                {showHome ? <Home gotoContact={startContact} gotoServices={startServices} /> : ""}
                {showAbout ? <About gotoContact={startContact} /> : ""}
                {showServices ? <Services/> : ""}
                {showContact ? <ContactHub /> : ""}
            </div>
            <div onClick={startContact}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </div>
    );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);