import * as React from "react";
import Contact from "./Contact";

export default function ContactHub(props) {
  return (
    <>
        <section className='sectionHeightContact sectionRelative'>
            <div className="backgroundContact"></div>
            <div className='font4 textPositionContact'>
                <div className="text4">Contact Us</div>
                <br />
                <div className="subtext4">
                    For immediate assistance, call
                    <br />
                    ~ 425-598-9302 ~
                </div>
            </div>
        </section>
        <section className='sectionHeightContact2 backgroundColor2'>
            <div className='container-fluid AppWidth'>
                <div className='row'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-5'>
                        <span className='imageBorderContact'>
                            <span className='iconsContact'>
                                <i className="fa fa-phone"></i>
                            </span>
                            <p className='titleContact2'>Call Us</p>
                            <p className='textContact'>
                                425.598.9302
                            </p>
                        </span>
                    </div>
                    <div className='col-sm-5'>
                        <span className='imageBorderContact'>
                            <span className='iconsContact'>
                                <i className="fa fa-envelope"></i>
                            </span>
                            <p className='titleContact2'>Email Us</p>
                            <p className='textContact'>
                                <a href="mailto:info@konpuconsulting.com">david@konpuconsulting.com</a>
                            </p>
                        </span>
                    </div>
                    <div className='col-sm-1'></div>
                </div>
            </div>
        </section>
        <section className='sectionHeightContact backgroundColor2'>
            <Contact />
        </section>
    </>
  );
};
