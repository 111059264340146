import * as React from "react";
import { EmailObject } from "../models/emailObject";
import { ToastContainer, toast } from 'react-toastify';

export default function Contact(props) {
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [name, setName] = React.useState();
  const [message, setMessage] = React.useState();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
  }

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  }

  const sendEmail = () => {
    if (name == undefined || name.length == 0 || email == undefined || email.length == 0 || !validateEmail(email)) {
      toast.warn('Please include a name and email', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

      return;
    }
    
    var newForm = new EmailObject();
    newForm.email = email;
    newForm.phone = phone;
    newForm.name = name;
    newForm.message = message;
    postForm(newForm);
  }

  const postForm = async (emailObject) => {
    const url = 'https://us-central1-websitebackend-74e97.cloudfunctions.net/kH1luS/p8lMqb';

    const response = await fetch(url, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailObject)
    })
    .then(() => toast.success('Message sent!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }))
    .catch(() => toast.error('Could not send message. Please try again later.', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }));
  }

  const validateEmail = (em: string) => {
    return RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(em);
  };

  return (
    <div className="container-fluid AppWidth">
      <br />
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className='col-sm-10 titleContact'>
          Contact Us
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='row form-group'>
        <div className='col-sm-1'></div>
        <div className='col-sm-10'>
          <input className="form-control" placeholder="Name" value={name || ''} onChange={(e) => handleName(e)} />
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='row form-group'>
        <div className='col-sm-1'></div>
        <div className='col-sm-5'>
          <input className="form-control" placeholder="Email" value={email || ''} onChange={(e) => handleEmail(e)} />
        </div>
        <div className='col-sm-5'>
          <input className="form-control" placeholder="Phone" value={phone || ''} onChange={(e) => handlePhone(e)} />
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='row form-group'>
        <div className='col-sm-1'></div>
          <div className='col-sm-10'>
            <textarea  className="form-control" rows="5" placeholder="Message" value={message || ''} onChange={(e) => handleMessage(e)}>
            </textarea>
          </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='row form-group'>
        <div className='col-sm-9'></div>
        <div className='col-sm-2'>
          <button className='btn buttonContact float-right' onClick={sendEmail}>Submit</button>
        </div>
        <div className='col-sm-1'></div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </div>
  );
};
