import * as React from "react";
import Contact from "./Contact";

export default function Home(props) {
  React.useEffect(() => {
  }, [])

  const gotoContact = () => {
    props.gotoContact();
  }

  const gotoServices = () => {
    props.gotoServices();
  }

  return (
    <>
      <section className="sectionRelative">
        <div className="background1"></div>
        <div className="font text1">Konpu Consulting</div>
        <div className="font subtext1">
          Accounting Automation~Software Services<br/>
          Call at 425-598-9302<br/>
          ¡SE HABLA ESPAÑOL!<br />
        </div>
        <button className='btn font buttonGoToContact1' onClick={gotoContact}>SCHEDULE A CONSULTATION</button>
      </section>
      <section className='sectionHeight backgroundColor2'>
        <div className='container-fluid AppWidth'>
          <div className='row'>
            <div className='col-sm-12 title2'>
              Software Solutions That Yield Results
            </div>
          </div>
          <div className='row row2'>
            <div className='col-sm-1'></div>
            <div className='col-sm-6'>
              <span className='imageBorder2'>
                <div className='background2'></div>
              </span>
            </div>
            <div className='col-sm-4'>
              <p className='text2'>
                Navigating financial data and bookkeeping on your own can lead to work crunch, unnecessary expenses and human error down the road.
                Make sure you use the proper tool for the job.
                Konpu Consulting can provide you with the individualized, professional software you need.
              </p>
              <button className='btn buttonGoToContact2' onClick={gotoContact}>LET'S WORK TOGETHER</button>
            </div>
            <div className='col-sm-1'></div>
          </div>
        </div>
      </section>
      <section className='sectionHeight'>
        <div className='container-fluid AppWidth'>
          <div className='row'>
            <div className='col-sm-12 title31'>
              Primary Services
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='background31'></div>
              <div className='title3'>Workflow automation</div>
              <div className='text3'>
                Streamline your accounting and finance workflow: Our custom software automates repetitive office tasks,
                allowing you to focus on what's important - your clients.
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='background32'></div>
              <div className='title3'>Data Gathering</div>
              <div className='text3'>
                Stop dealing with invoice services
                that require hours of work to gather
                your company's data. We'll get it in a matter of a few clicks.
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='background33'></div>
              <div className='title3'>Personalized and Reliable</div>
              <div className='text3'>
                There are no one-size-fits-all solutions.
                Your software will be tailored made to meet the
                needs of your business.
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <button className='btn buttonGoToServices button3' onClick={gotoServices}>Learn More</button>
            </div>
          </div>
        </div>
      </section>
      <section className='sectionRelative'>
        <div className="background4"></div>
        <div className='font4 textPosition4'>
          <div className="text4">Need a Consultation?</div>
          <div className="subtext4">
            Simplify your accounting and financial workflow today,<br />
            call Engineer David Kutugata immediately at 425-598-9302.<br />
            ¡SE HABLA ESPAÑOL!
          </div>
        </div>
        <div className='font4 buttonPosition4'>
          <button className='btn button4' onClick={gotoContact}>GET IN TOUCH</button>
        </div>
      </section>
      <section className='sectionHeight backgroundColor2'>
        <Contact />
      </section>
    </>
  );
}
